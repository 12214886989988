<template>
  <div class="h-full">
    <div class="flex w-full h-full max-w-screen-xl mx-auto p-4">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { initFlowbite } from 'flowbite';

export default {
  name: 'App',
  mounted() {
    this.$nextTick(function () {
      initFlowbite();

      const html = document.querySelector('html');
      const theme = localStorage.getItem('theme');

      if (theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        html.classList.add('dark');
        document.body.classList.add('dark:bg-gray-900');
        document.body.classList.add('dark:text-gray-300');
      } else {
        html.classList.remove('dark');
        document.body.classList.remove('dark:bg-gray-900');
        document.body.classList.remove('dark:text-gray-300');
      }
    });
  }
}
</script>
