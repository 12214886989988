import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import App from './App.vue'
import routes from './routes'
import store from './store'
import './index.css'

Vue.config.productionTip = false

new Vue({
  router: new Router({
    routes,
    mode: 'history'
  }),
  store,
  render: h => h(App),
}).$mount('#app')
