export default {
    state: {
        application: null
    },
    getters: {
        getApplication(state) {
            return state.application
        }
    },
    actions: {
        setApplication({ commit }, application) {
            commit('setApplication', application)
        }
    },
    mutations: {
        setApplication(state, application) {
            state.application = application
        }
    }
}